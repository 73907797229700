<template>
	<div>
		<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:paginate-elements-by-height="1400"
			filename="Cotizacion"
			:pdf-quality="1.5"
			:manual-pagination="false"
			pdf-format="a4"
			pdf-orientation="portrait"
			pdf-content-width="800px"
			@progress="onProgress($event)"
			@hasStartedGeneration="hasStartedGeneration()"
			@hasGenerated="hasGenerated($event)"
			ref="html2Pdf"
		>
			<section slot="pdf-content">
				<div class="relative" style="height: 1122px">
					<div class="header"></div>

					<div class="flex justify-between z-20">
						<div class="mt-5 ml-10 w-64">
							<img
								style="width: 100px; height: 67.5px"
								src="../../public/img/logoPequeño.png"
								alt="logo pequeno"
							/>
						</div>
						<div>
							<h1 class="text-3xl pt-10 mr-40">COTIZACION</h1>
						</div>
					</div>

					<div class="flex justify-between pt-5 z-20">
						<div class="w-8/12">
							<div class="flex">
								<p class="font-bold ml-5">Nombre:</p>
								<p class="text-white">D</p>
								<p>{{ dataTotal.nombre }}</p>
							</div>
							<div class="flex">
								<p class="font-bold ml-5">Dirección:</p>
								<p class="text-white">D</p>
								<p>
									{{ dataTotal.direccion }}
								</p>
							</div>
							<div class="flex">
								<p class="font-bold ml-5">Telefono:</p>
								<p class="text-white">D</p>
								<p>{{ dataTotal.telefono }}</p>
							</div>
						</div>
						<div class="mr-12">
							<div class="flex">
								<p class="font-bold">N° Cotización:</p>
								<p class="text-white">D</p>
								<p>{{ dataTotal.cotizacion }}</p>
							</div>
							<div class="flex">
								<p class="font-bold">Fecha:</p>
								<p class="text-white">D</p>
								<p>{{ dataTotal.fecha }}</p>
							</div>
						</div>
					</div>

					<div class="absolute inset-x-0">
						<div class="flex justify-center mt-3">
							<table id="cotizarr" class="table-auto mt-4">
								<thead>
									<tr class="border-b-2 border-black">
										<th class="px-4 py-2">Cantidad</th>
										<th class="px-4 py-2">Und</th>
										<th class="px-4 py-2">Producto</th>
										<th class="px-4 py-2">Precio Bs</th>
										<th class="px-4 py-2">Total Bs</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(cotizaciont, k) in cotizacionPagina1[0]" :key="k">
										<td class="text-center text-sm">
											{{ formato(cotizaciont.Cantidad) }}
										</td>
										<td class="text-center text-sm">
											{{ cotizaciont.Unidad }}
										</td>
										<td class="text-center text-sm">
											{{ cotizaciont.Producto }}
										</td>
										<td class="text-center text-sm px-4 py-1">
											{{ formato(cotizaciont.PrecioUnitarioBs) }}
										</td>
										<td class="text-center text-sm">
											{{ formato(cotizaciont.MontoCobrarBs) }}
										</td>
									</tr>
									<tr v-if="primeraPagina">
										<td></td>
										<td></td>
										<td></td>
										<td class="text-center border-b-2 border-black text-sm">
											Exento
										</td>
										<td class="text-center border-b-2 border-black text-sm">
											{{ formato(dataTotal.exentoBs) }}
										</td>
									</tr>
									<tr v-if="primeraPagina">
										<td></td>
										<td></td>
										<td></td>
										<td class="text-center border-b-2 border-black text-sm">
											Subtotal
										</td>
										<td class="text-center border-b-2 border-black text-sm">
											{{ formato(dataTotal.subtotalbs) }}
										</td>
									</tr>
									<tr v-if="primeraPagina">
										<td></td>
										<td></td>
										<td></td>
										<td class="text-center border-b-2 border-black text-sm">
											IVA
										</td>
										<td class="text-center border-b-2 border-black text-sm">
											{{ formato(dataTotal.ivabs) }}
										</td>
									</tr>
									<tr v-if="primeraPagina">
										<td></td>
										<td></td>
										<td></td>
										<td class="text-center text-sm">Total</td>
										<td class="text-center text-sm">
											{{ formato(dataTotal.totalbs) }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="flex justify-center mt-10">
						<img
							class="w-5/6"
							src="../../public/img/logoGrande.png"
							alt="logoGrande"
						/>
					</div>
					<div
						class="absolute flex bg-verdeShopper h-20 items-center w-full inset-x-0 bottom-0"
					>
						<p class="mx-20 text-center items-center text-sm text-white bold">
							DOMICILIO FISCAL AV MOHEDANO EDIF MERCADO MUNICIPAL DE CHACAO,
							NUEVA SEDE PISO MEZZANINA LOCAL DM8 URB CHACAO CARACAS (CHACAO)
							MIRANDA ZONA POSTAL 1080. tel: 0212-262-20-98
						</p>
					</div>
				</div>
			</section>

			<div class="html2pdf__page-break" />
			<!-- Segunda Pagina!!!!!!! -->

			<section
				v-if="productosCotizacionT.length > lineasPaginas"
				slot="pdf-content"
			>
				<div v-for="(cotizacionPagina, z) in dataPDF" :key="z">
					<div class="relative" style="height: 1122px">
						<div class="header"></div>

						<div class="flex justify-between z-20">
							<div class="mt-5 ml-10">
								<img
									style="width: 100px; height: 67.5px"
									src="../../public/img/logoPequeño.png"
									alt="logo pequeno"
								/>
							</div>
						</div>

						<div class="absolute inset-x-0 mt-3">
							<div class="flex justify-center">
								<table id="cotizarr" class="table-auto mt-4">
									<thead>
										<tr class="border-b-2 border-black">
											<th class="px-4 py-2">Cantidad</th>
											<th class="px-4 py-2">Und</th>
											<th class="px-4 py-2">Producto</th>
											<th class="px-4 py-2">Precio Bs</th>
											<th class="px-4 py-2">Total Bs</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(cotizaciont, k) in cotizacionPagina" :key="k">
											<td class="text-center text-sm">
												{{ formato(cotizaciont.Cantidad) }}
											</td>
											<td class="text-center text-sm">
												{{ cotizaciont.Unidad }}
											</td>
											<td class="text-center text-sm">
												{{ cotizaciont.Producto }}
											</td>
											<td class="text-center text-sm px-4 py-1">
												{{ formato(cotizaciont.PrecioUnitarioBs) }}
											</td>
											<td class="text-center text-sm">
												{{ formato(cotizaciont.MontoCobrarBs) }}
											</td>
										</tr>
										<tr v-if="z >= dataPDF.length - 1">
											<td></td>
											<td></td>
											<td></td>
											<td class="text-center border-b-2 border-black text-sm">
												Exento
											</td>
											<td class="text-center border-b-2 border-black text-sm">
												{{ formato(dataTotal.exentoBs) }}
											</td>
										</tr>
										<tr v-if="z >= dataPDF.length - 1">
											<td></td>
											<td></td>
											<td></td>
											<td class="text-center border-b-2 border-black text-sm">
												Subtotal
											</td>
											<td class="text-center border-b-2 border-black text-sm">
												{{ formato(dataTotal.subtotalbs) }}
											</td>
										</tr>
										<tr v-if="z >= dataPDF.length - 1">
											<td></td>
											<td></td>
											<td></td>
											<td class="text-center border-b-2 border-black text-sm">
												IVA
											</td>
											<td class="text-center border-b-2 border-black text-sm">
												{{ formato(dataTotal.ivabs) }}
											</td>
										</tr>
										<tr v-if="z >= dataPDF.length - 1">
											<td></td>
											<td></td>
											<td></td>
											<td class="text-center text-sm">Total</td>
											<td class="text-center text-sm">
												{{ formato(dataTotal.totalbs) }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="flex justify-center">
							<img
								class="w-5/6 mt-40"
								src="../../public/img/logoGrande.png"
								alt="logoGrande"
							/>
						</div>
						<div
							class="absolute flex bg-verdeShopper h-20 items-center w-full inset-x-0 bottom-0"
						>
							<p class="mx-20 text-center items-center text-sm text-white bold">
								DOMICILIO FISCAL AV MOHEDANO EDIF MERCADO MUNICIPAL DE CHACAO,
								NUEVA SEDE PISO MEZZANINA LOCAL DM8 URB CHACAO CARACAS (CHACAO)
								MIRANDA ZONA POSTAL 1080. tel: 0212-262-20-98
							</p>
						</div>
					</div>
				</div>
			</section>

			<div class="html2pdf__page-break" />
		</vue-html2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
	data() {
		return {
			productosCotizacionT: [],
			paginacion: 0,
			dataTotal: {},
			primeraPagina: false,
			dataPDF: [],
			cotizacionPagina1: [],
			nombrePDF: "nombre",
			lineasPaginas: 22
		};
	},
	mounted() {
		//igualar variables
		this.dataTotal = this.nuevaData();
		console.log(this.dataTotal);
		this.productosCotizacionT = this.dataTotal.pedido;
		//verificacion de tamano de array para ver paginas a llenar
		if (this.productosCotizacionT.length <= this.lineasPaginas) {
			this.cotizacionPagina1.push(
				this.productosCotizacionT.slice(0, this.lineasPaginas)
			);
			this.primeraPagina = true;
		} else {
			this.paginacion =
				parseInt(this.productosCotizacionT.length / this.lineasPaginas) + 1;
			console.log("paginacion", this.paginacion);
			let index = 0;
			if (this.paginacion <= 2) {
				this.cotizacionPagina1.push(
					this.productosCotizacionT.slice(0, this.lineasPaginas - 1)
				);
				this.dataPDF.push(
					this.productosCotizacionT.slice(this.lineasPaginas - 1, 50)
				);
			} else {
				//Primera página
				this.cotizacionPagina1.push(
					this.productosCotizacionT.slice(0, this.lineasPaginas)
				);
				//Resto de páginas
				for (let i = 0; i < this.paginacion - 1; i++) {
					if (i < 1) {
						if (index + this.lineasPaginas < this.productosCotizacionT.length) {
							this.dataPDF.push(
								this.productosCotizacionT.slice(
									this.lineasPaginas + index,
									this.lineasPaginas * 2 + index + 5
								)
							);
							index += this.lineasPaginas;
						} else {
							i = this.paginacion;
						}
						//Corrección de lineas para el resto de las páginas
						this.lineasPaginas += 5;
					} else {
						if (index + this.lineasPaginas < this.productosCotizacionT.length) {
							this.dataPDF.push(
								this.productosCotizacionT.slice(
									this.lineasPaginas + index,
									this.lineasPaginas * 2 + index
								)
							);
							index += this.lineasPaginas;
						} else {
							i = this.paginacion;
						}
					}
				}
			}
		}

		this.generateReport();
	},
	methods: {
		formato(args) {
			return new Intl.NumberFormat("de-DE").format(parseFloat(args).toFixed(2));
		},
		/*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
		generateReport() {
			this.$refs.html2Pdf.generatePdf();
		},
		nuevaData() {
			return this.$store.state.pdfData;
		}
	},

	components: {
		VueHtml2pdf
	}
};
</script>

<style>
#sideBar {
	display: none;
}
#cabecera {
	display: none;
}

.header {
	background-color: #005226;
	width: auto;
	height: 3rem;
}
#subheader {
	display: flex;
}

#cotizacionNombre {
	float: right;
}

#smallLogo {
	float: left;
	padding-top: 0.7rem;
	margin-left: 2rem;
	width: 6.3rem;
	height: 5rem;
}
</style>
